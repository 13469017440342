import { Resource, epochISOString, epochISODateString, toISODate } from 'idea-toolbox';

import { User, UserAttached } from './user.model';
import { TrainingModel } from './trainingModel.model';
import { Patient } from './patient.model';
import { MedicalCenter } from './medicalCenter.model';
import { PreviousDevices } from './previousDevices.model';
import { Data } from './data.model';
import { Product, ProductTypes, ProductUsed } from './product.model';
import { ASLMedicalCenter } from './aslMedicalCenter.model';

export class Training extends Resource {
  /**
   * The ID of the training.
   */
  trainingId: string;
  /**
   * The ID of the training model.
   */
  modelId: string;
  /**
   * Representative color, derived from the training model.
   */
  color: string;
  /**
   * The user creating the training.
   */
  user: UserAttached;
  /**
   * The id of the user creating the training.
   * Used in indexes.
   */
  userId: string;
  /**
   * The ID of the company for which the user created the training.
   */
  companyId: string;
  /**
   * Timestamp of creation.
   */
  createdAt: epochISOString;
  /**
   * Year of creation.
   */
  createdOnYear: number;
  /**
   * Additional notes of the training.
   */
  notes: string;
  /**
   * Describe, if set, the external source where the training is originated, such as OTM.
   */
  externalSource?: 'OTM';
  /**
   * Internal field to show and sort the training among list of active ones.
   *  - During stage 1 it equals to `createdAt`.
   *  - During stage 2 it equals to `outcomeAt` or `confirmedAt`.
   *  - ... afterwards, it's not set.
   */
  inProgressAt?: epochISOString;

  //
  // STAGE 1 - Training (closed by confirmedAt)
  //

  /**
   * Patient bio.
   */
  patient: Patient;
  /**
   * Previous user's devices.
   */
  previousDevices: PreviousDevices;
  /**
   * The training's mode.
   */
  mode: TrainingModes;
  /**
   * The medical center for the training.
   */
  medicalCenter: MedicalCenter;
  /**
   * ID of the data privacy agreement.
   */
  privacyBlockNum: string;
  /**
   * The checklist filled-in for the training, based on the model.
   */
  checklist: { [value: string]: boolean };
  /**
   * The product used in the training.
   */
  products: { [slot: string]: ProductUsed };
  /**
   * The URI of the attachments necessary for the training.
   */
  attachments: { [slot: string]: string };
  /**
   * Timestamp suggestion: when the training should end.
   */
  shouldEndAt: epochISODateString;
  /**
   * Whether (and when) the training was confirmed (closes stage 1).
   */
  confirmedAt?: epochISOString;
  /**
   * A set of ignored errors (if any) during the confirmation of a training.
   */
  ignoredErrorsOnConfirm?: string[];

  //
  // STAGE 2 - Outcome and closure (started by outcomeAt, closed by outcomeSentAt)
  //
  /**
   * Whether (and when) the training started stage 2.
   */
  outcomeAt?: epochISOString;
  /**
   * The outcome (positive or negative) for the training.
   */
  outcome?: TrainingOutcome;
  /**
   * Whether (and when) the outcome of the training was sent.
   */
  outcomeSentAt?: epochISOString;
  /**
   * The IDs of the products to be recovered.
   */
  productsToRecoverIds?: string[];
  /**
   * Whether some trial equipment was recovered after being out.
   */
  trialEquipmentRecoveredAt?: epochISOString;
  /**
   * The (optional) external document attached to the training.
   */
  linkedDocument?: DocumentAttached;

  load(x: any, options: { model: TrainingModel }): void {
    super.load(x);
    this.trainingId = this.clean(x.trainingId, String);
    if (options.model) {
      this.modelId = this.clean(options.model.modelId, String);
      this.color = this.clean(options.model.color, String);
    } else {
      this.modelId = this.clean(x.modelId, String);
      this.color = this.clean(x.color, String);
    }
    this.user = new UserAttached(x.user);
    this.userId = this.user.userId;
    this.companyId = this.clean(x.companyId, String);
    this.createdAt = this.clean(x.createdAt, t => new Date(t).toISOString(), new Date().toISOString());
    this.createdOnYear = Number(this.createdAt.slice(0, 4));
    this.inProgressAt = this.createdAt;
    this.notes = this.clean(x.notes, String);
    if (x.externalSource) this.externalSource = this.clean(x.externalSource, String);

    this.patient = new Patient(x.patient);
    this.previousDevices = new PreviousDevices(x.previousDevices);
    this.mode = this.clean(x.mode, String);
    this.medicalCenter = new MedicalCenter(x.medicalCenter);
    this.privacyBlockNum = this.clean(x.privacyBlockNum, String);
    if (options.model) {
      this.checklist = {};
      if (x.checklist)
        options.model.checklist.forEach(check => {
          this.checklist[check.value] = this.clean(x.checklist[check.value], Boolean);
        });
      this.products = {};
      if (x.products)
        options.model.productSlots.forEach(slot => {
          if (x.products[slot.type]) this.products[slot.type] = new ProductUsed(x.products[slot.type]);
        });
      this.attachments = {};
      if (x.attachments)
        options.model.attachmentSlots.forEach(slot => {
          if (x.attachments[slot]) this.attachments[slot] = this.clean(x.attachments[slot], String);
        });
    } else {
      this.checklist = x.checklist || {};
      this.products = x.products || {};
      this.attachments = x.attachments || {};
    }
    this.shouldEndAt = this.clean(x.shouldEndAt, t => toISODate(t));

    if (x.confirmedAt) {
      this.confirmedAt = this.clean(x.confirmedAt, t => new Date(t).toISOString());
      this.inProgressAt = this.confirmedAt;

      this.ignoredErrorsOnConfirm = this.cleanArray(x.ignoredErrorsOnConfirm, String);

      if (x.outcomeAt) {
        this.outcomeAt = this.clean(x.outcomeAt, t => new Date(t).toISOString());
        this.inProgressAt = this.outcomeAt;

        this.outcome = new TrainingOutcome(x.outcome);
        if (x.outcomeSentAt) this.outcomeSentAt = this.clean(x.outcomeSentAt, t => new Date(t).toISOString());
        this.productsToRecoverIds = this.cleanArray(x.productsToRecoverIds, String);
        if (x.trialEquipmentRecoveredAt)
          this.trialEquipmentRecoveredAt = this.clean(x.trialEquipmentRecoveredAt, t => new Date(t).toISOString());

        if (this.outcomeSentAt && !this.shouldRecoverTrialEquipment()) delete this.inProgressAt;
      }

      if (x.linkedDocument) this.linkedDocument = new DocumentAttached(x.linkedDocument);
    }
  }

  safeLoad(newData: any, safeData: any, options: { model: TrainingModel }): void {
    super.safeLoad(newData, safeData, options);
    this.trainingId = safeData.trainingId;
    this.modelId = safeData.modelId;
    this.color = safeData.color;
    this.user = safeData.user;
    this.userId = safeData.userId;
    this.companyId = safeData.companyId;
    this.createdAt = safeData.createdAt;
    this.createdOnYear = safeData.createdOnYear;
    if (safeData.externalSource) this.externalSource = safeData.externalSource;

    if (safeData.confirmedAt) this.confirmedAt = safeData.confirmedAt;
    if (safeData.outcomeAt) this.outcomeAt = safeData.outcomeAt;
    if (safeData.outcome) this.outcome = safeData.outcome;
    if (safeData.outcomeSentAt) this.outcomeSentAt = safeData.outcomeSentAt;
    if (safeData.inProgressAt) this.inProgressAt = safeData.inProgressAt;
    if (safeData.trialEquipmentRecoveredAt) this.trialEquipmentRecoveredAt = safeData.trialEquipmentRecoveredAt;
    if (safeData.linkedDocument) this.linkedDocument = safeData.linkedDocument;
  }

  validate(options: { model: TrainingModel; data: Data; products: Product[] }): string[] {
    const e = super.validate();
    if (this.iE(this.modelId)) e.push('modelId');
    this.patient.validate(options).forEach(ea => e.push(`patient.${ea}`));
    this.previousDevices.validate(options).forEach(ea => e.push(`previousDevices.${ea}`));
    if (!Object.values(TrainingModes).includes(this.mode)) e.push('mode');
    this.medicalCenter.validate(options).forEach(ea => e.push(`medicalCenter.${ea}`));
    if (this.iE(this.privacyBlockNum)) e.push('privacyBlockNum');
    options.model.productSlots.forEach(slot => {
      const productUsed = this.products[slot.type];
      if (!productUsed) e.push(`products[${slot.type}]`);
      else {
        const product = options.products.find(p => p.productId === productUsed.productId);
        if (!product) e.push(`products[${slot.type}].productId`);
        else productUsed.validate(product).forEach(ea => e.push(`products[${slot.type}].${ea}`));
      }
    });
    if (this.iE(this.shouldEndAt, 'date')) e.push('shouldEndAt');

    if (this.getStage() >= TrainingStages.HAS_OUTCOME)
      this.outcome.validate(options).forEach(ea => e.push(`outcome[${ea}]`));

    return e;
  }

  getStage(): TrainingStages {
    if (this.outcomeSentAt) return TrainingStages.OUTCOME_SENT;
    if (this.outcomeAt) return TrainingStages.HAS_OUTCOME;
    if (this.linkedDocument) return TrainingStages.DOCUMENT_LINKED;
    if (this.confirmedAt) {
      if (new Date(this.shouldEndAt) < new Date()) return TrainingStages.CONFIRMED_ENDED;
      else return TrainingStages.CONFIRMED;
    }
    return TrainingStages.DRAFT;
  }
  getNumericStage(): number {
    const stage = this.getStage();
    return stage >= TrainingStages.HAS_OUTCOME ? 2 : stage >= TrainingStages.CONFIRMED ? 1 : 0;
  }

  isDraftCreatedOffline(): boolean {
    if (!this.trainingId) return false;
    return this.trainingId.startsWith('OFFLINE_');
  }

  isFromExternalSource(): boolean {
    return Boolean(this.externalSource);
  }

  shouldRecoverTrialEquipment(): boolean {
    return (
      this.getStage() >= TrainingStages.HAS_OUTCOME &&
      this.productsToRecoverIds?.length &&
      !this.trialEquipmentRecoveredAt
    );
  }

  canBeManagedBy(user: User, aslMedicalCenters: ASLMedicalCenter[]): boolean {
    return (
      user.isAdmin(this.companyId) ||
      user.canManageBasedOnAslRegion(this.medicalCenter.aslId, aslMedicalCenters) ||
      user.isRobot() ||
      user.canExportSensitiveData() ||
      (user.isExternal() && this.isFromExternalSource()) ||
      user.userId === this.user.userId
    );
  }

  isInWaitingList(model: TrainingModel): boolean {
    if (!model) return false;
    return this.getStage() === TrainingStages.DRAFT && model.hasWaitingList;
  }
}

export class TrainingOutcome extends Resource {
  /**
   * The positive/negative result of the training.
   */
  result: TrainingOutcomes;
  /**
   * Details describing the outcome.
   */
  details: string;

  load(x: any): void {
    super.load(x);
    this.result = x.result ? TrainingOutcomes.POSITIVE : TrainingOutcomes.NEGATIVE;
    this.details = this.clean(x.details, String);
  }

  validate(options: { model: TrainingModel; data: Data }): string[] {
    const e = super.validate();

    if (!(this.result in TrainingOutcomes)) e.push('result');
    if (
      this.result === TrainingOutcomes.NEGATIVE &&
      !options.data.negativeOutcomeReasonsByModelType[options.model.type]?.includes(this.details)
    )
      e.push('details');

    return e;
  }
}

export class DocumentAttached extends Resource {
  externalDocumentNum: string;
  internalDocumentNum?: string;
  date: epochISODateString;

  load(x: any): void {
    super.load(x);
    this.externalDocumentNum = this.clean(x.externalDocumentNum, String);
    this.internalDocumentNum = this.clean(x.internalDocumentNum, String);
    this.date = this.clean(x.date, d => toISODate(d));
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.externalDocumentNum)) e.push('externalDocumentNum');
    if (this.iE(this.date, 'date')) e.push('date');
    return e;
  }
}

export enum TrainingModes {
  DEMO = 'Training demo',
  DEFINITIVE = 'Training definitivo'
}

export enum TrainingOutcomes {
  NEGATIVE = 0,
  POSITIVE = 1
}

export enum TrainingStages {
  DRAFT,
  // STAGE 1
  CONFIRMED,
  CONFIRMED_ENDED,
  DOCUMENT_LINKED,
  // STAGE 2
  HAS_OUTCOME,
  OUTCOME_SENT
}
export enum TrainingTags {
  // could be used concurrently to TrainingStages; hence, starts from a higher number
  SHOULD_RECOVER_TRIAL_EQUIPMENT = 50
}

export enum TrainingSegments {
  DASHBOARD = 'DASHBOARD',
  BOOKINGS = 'BOOKINGS'
}

/**
 * Flat, exportable version of a training.
 */
export class ExportableTraining {
  trainingId: string;
  companyId: string;
  specialist: string;
  modelId: string;
  aslId: string;
  monitoring: string;
  infusion: string;
  medicalCenterCity: string;
  medicalCenterHospital: string;
  medicalCenterArea: string;
  medicalCenterMedic: string;
  stage: string;
  createdAt: string;
  confirmedAt: string;
  outcomeSentAt: string;
  shouldEndAt: string;
  serials: string;
  outcome: string;
  outcomeDetails: string;
  description: string;
  podOrSensorStartDate?: string;
  podOrSensorQty?: number;
  definitive: string;
  ignoredErrorsOnConfirm: string;
  trainingStatus: string;

  constructor(t: Training) {
    this.trainingId = t.trainingId;
    this.companyId = t.companyId;
    this.specialist = t.user.name;
    this.modelId = t.modelId;
    this.aslId = t.patient.aslId;
    this.monitoring = t.previousDevices.monitoring;
    this.infusion = t.previousDevices.infusion;
    this.medicalCenterCity = t.medicalCenter.city;
    this.medicalCenterHospital = t.medicalCenter.hospital;
    this.medicalCenterArea = t.medicalCenter.area;
    this.medicalCenterMedic = t.medicalCenter.medic.fullName;
    this.stage = `${t.getNumericStage()}/2`;
    this.createdAt = t.createdAt;
    this.confirmedAt = t.confirmedAt;
    this.outcomeSentAt = t.outcomeSentAt;
    this.shouldEndAt = t.shouldEndAt;
    this.serials = Object.values(t.products)
      .map(p => p.serialNumber)
      .filter(x => x)
      .join(', ');
    if (t.outcomeAt) {
      if (t.outcome.result === 1) {
        this.outcome = 'Positivo';
        this.outcomeDetails = '';
      } else {
        this.outcome = 'Negativo';
        this.outcomeDetails = t.outcome.details;
      }
    } else {
      this.outcome = '';
      this.outcomeDetails = '';
    }
    let podOrSensor: ProductUsed;
    if (t.products[ProductTypes.POD]) podOrSensor = t.products[ProductTypes.POD];
    else if (t.products[ProductTypes.SENSOR]) podOrSensor = t.products[ProductTypes.SENSOR];
    if (podOrSensor) {
      if (podOrSensor.startDate) this.podOrSensorStartDate = toISODate(podOrSensor.startDate);
      if (podOrSensor.qty) this.podOrSensorQty = podOrSensor.qty;
    }
    this.definitive = t.mode;
    this.ignoredErrorsOnConfirm = t.ignoredErrorsOnConfirm?.join(', ');
    this.trainingStatus = TrainingStages[t.getStage()];
  }
}

/**
 * Flat, exportable version of a training with the patient's data.
 */
export class ExportableTrainingWithPatient extends ExportableTraining {
  surname: string;
  name: string;
  address: string;
  city: string;
  district: string;
  postalCode: string;
  aslId: string;
  birthdate: epochISODateString;
  cellphone: string;
  email: string;
  phone: string;
  legalGuardian: string;
  taxCode: string;

  constructor(t: Training) {
    super(t);
    this.surname = t.patient.surname;
    this.name = t.patient.name;
    this.address = t.patient.address;
    this.city = t.patient.city;
    this.district = t.patient.district;
    this.postalCode = t.patient.postalCode;
    this.aslId = t.patient.aslId;
    this.birthdate = t.patient.birthdate;
    this.cellphone = t.patient.cellphone;
    this.email = t.patient.email;
    this.phone = t.patient.phone;
    this.legalGuardian = t.patient.legalGuardian;
    this.taxCode = t.patient.taxCode;
  }
}
