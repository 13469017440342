/**
 * The resources supporting the delta mechanism.
 */
export enum DeltaResources {
  DATA = 'DATA',
  COMPANIES = 'COMPANIES',
  PRODUCTS = 'PRODUCTS',
  TRAINING_MODELS = 'TRAINING_MODELS',
  ASL_MEDICAL_CENTERS = 'ASL_MEDICAL_CENTERS',
  ASL_PATIENTS = 'ASL_PATIENTS',
  ASL_MEDICS = 'ASL_MEDICS'
}
