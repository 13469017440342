import { epochISODateString, Resource, toISODate } from 'idea-toolbox';

export class Product extends Resource {
  /**
   * The ID of the product.
   */
  productId: string;
  /**
   * A descriptive name for the product.
   */
  name: string;
  /**
   * The type of product.
   */
  type: ProductTypes;
  /**
   * A series of hints, useful for handling the product.
   */
  hints: string[];
  /**
   * The level of check for the lots.
   */
  usesLots: ModeOfUse;
  /**
   * The level of check for the serial numbers.
   */
  usesSerialNumbers: ModeOfUse;
  /**
   * Whether the product needs a reference to when it starts to be used.
   */
  usesStartDate: boolean;
  /**
   * If any, the possible conditions in which the product can be sold.
   */
  conditions: string[];

  load(x: any): void {
    super.load(x);
    this.productId = this.clean(x.productId, String);
    this.name = this.clean(x.name, String);
    this.type = this.clean(x.type, String);
    this.hints = this.cleanArray(x.hints, String);
    this.usesLots = this.clean(x.usesLots, Number, ModeOfUse.REQUIRED_WITH_PATTERN);
    this.usesSerialNumbers = this.clean(x.usesSerialNumbers, Number, ModeOfUse.REQUIRED_WITH_PATTERN);
    this.usesStartDate = this.clean(x.usesStartDate, Boolean);
    this.conditions = this.cleanArray(x.conditions, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.productId = safeData.productId;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.name)) e.push('name');
    if (!Object.values(ProductTypes).includes(this.type)) e.push('type');
    return e;
  }
}

export enum ProductTypes {
  TRANSMITTER = 'Trasmettitore',
  RECEIVER = 'Ricevitore',
  SENSOR = 'Sensore',
  POD = 'POD',
  PDM = 'PDM',
  THERAS = 'Theras'
}

export class ProductUsed extends Resource {
  /**
   * The ID of the product.
   */
  productId: string;
  /**
   * A descriptive name for the product.
   */
  name: string;
  /**
   * The quantity delivered.
   */
  qty: number;
  /**
   * The lot of the product, if any.
   */
  lot?: string;
  /**
   * The serial number of the product, if any.
   */
  serialNumber?: string;
  /**
   * A reference to when the product was firstly used.
   */
  startDate?: epochISODateString;
  /**
   * The condition in which the product was delivered.
   */
  condition?: string;
  /**
   * Optional notes on the product used.
   */
  notes: string;

  load(x: any): void {
    super.load(x);
    this.productId = this.clean(x.productId, String);
    this.name = this.clean(x.name, String);
    this.qty = this.clean(x.qty, Number, 1);
    if (x.lot) this.lot = this.clean(x.lot, String);
    if (x.serialNumber) this.serialNumber = this.clean(x.serialNumber, String);
    if (x.startDate) this.startDate = this.clean(x.startDate, d => toISODate(d));
    if (x.condition) this.condition = this.clean(x.condition, String);
    this.notes = this.clean(x.notes, String);
  }

  validate(product: Product): string[] {
    const e = super.validate();
    if (this.iE(this.productId)) e.push('productId');
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.qty)) e.push('qty');
    if (!this.isLotValid(product, this.lot)) e.push('lot');
    if (!this.isSerialNumberValid(product, this.serialNumber)) e.push('serialNumber');
    if (product.usesStartDate && this.iE(this.startDate)) e.push('startDate');
    if (product.conditions.length && !product.conditions.includes(this.condition)) e.push('condition');
    return e;
  }

  /**
   * Get the pattern to check the validity of the lot inputed.
   */
  getPatternOfLot(product: Product): string {
    if (product.usesLots === ModeOfUse.NOT_REQUIRED_NO_PATTERN || product.usesLots === ModeOfUse.REQUIRED_NO_PATTERN)
      return null;

    if (product.usesLots && product.type === ProductTypes.SENSOR) return '^(\\d){7}$';
    else return null;
  }
  /**
   * Whether the lot inputed is valid, based on the pattern assigned.
   */
  isLotValid(product: Product, str: string): boolean {
    if (product.usesLots >= ModeOfUse.REQUIRED_NO_PATTERN && this.iE(this.lot)) return false;
    if (product.usesLots < ModeOfUse.REQUIRED_NO_PATTERN && this.iE(this.lot)) return true;

    const pattern = this.getPatternOfLot(product);
    return !pattern || new RegExp(pattern).test(str);
  }

  /**
   * Get the pattern to check the validity of the serial number inputed.
   */
  getPatternOfSerialNumber(product: Product): string {
    if (
      product.usesSerialNumbers === ModeOfUse.NOT_REQUIRED_NO_PATTERN ||
      product.usesSerialNumbers === ModeOfUse.REQUIRED_NO_PATTERN
    )
      return null;

    if (!product.usesSerialNumbers) return null;
    switch (product.type) {
      case ProductTypes.TRANSMITTER:
        return '^[\\w\\d]{6}$';
      case ProductTypes.PDM:
        return '^[\\w\\d-]{9}(?:\\d{3})?$';
      case ProductTypes.RECEIVER:
        return '^(?:[\\w\\d]{10}|[\\w\\d]{18})$';
      default:
        return null;
    }
  }
  /**
   * Whether the serial number inputed is valid, based on the pattern assigned.
   */
  isSerialNumberValid(product: Product, str: string): boolean {
    if (product.usesSerialNumbers >= ModeOfUse.REQUIRED_NO_PATTERN && this.iE(this.serialNumber)) return false;
    if (product.usesSerialNumbers < ModeOfUse.REQUIRED_NO_PATTERN && this.iE(this.serialNumber)) return true;

    const pattern = this.getPatternOfSerialNumber(product);
    return !pattern || new RegExp(pattern).test(str);
  }
}

export enum ModeOfUse {
  NOT_USED = 0,
  NOT_REQUIRED_NO_PATTERN = 10,
  NOT_REQUIRED_WITH_PATTERN = 20,
  REQUIRED_NO_PATTERN = 30,
  REQUIRED_WITH_PATTERN = 40
}
