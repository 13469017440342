import { CacheableResource } from '@idea-ionic/common';

import { DeltaResources } from '@models/deltaResources.enum';
import { Company } from '@models/company.model';
import { Product } from '@models/product.model';
import { TrainingModel } from '@models/trainingModel.model';
import { ASLMedicalCenter } from '@models/aslMedicalCenter.model';
import { ASLPatient } from '@models/aslPatient.model';
import { ASLMedic } from '@models/aslMedic.model';

export class DataCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.DATA, description);
  }
  listURL(): string {
    return 'data';
  }
  elementURL(data: any): string {
    return `data/${encodeURIComponent(data.id)}`;
  }
  findIndexInList(list: any[], element: any): number {
    return list.findIndex(x => x.id === element.id);
  }
  sort(): number {
    return; // not sorted
  }
}

export class CompanyCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.COMPANIES, description);
  }
  listURL(): string {
    return 'companies';
  }
  elementURL(element: Company): string {
    return `companies/${encodeURIComponent(element.companyId)}`;
  }
  findIndexInList(list: Company[], element: Company): number {
    return list.findIndex(x => x.companyId === element.companyId);
  }
  sort(a: Company, b: Company): number {
    return a.companyId.localeCompare(b.companyId);
  }
}

export class ProductsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.PRODUCTS, description);
  }
  listURL(): string {
    return 'products';
  }
  elementURL(element: Product): string {
    return `products/${encodeURIComponent(element.productId)}`;
  }
  findIndexInList(list: Product[], element: Product): number {
    return list.findIndex(x => x.productId === element.productId);
  }
  sort(a: Product, b: Product): number {
    return a.productId.localeCompare(b.productId);
  }
}

export class TrainingModelsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.TRAINING_MODELS, description);
  }
  listURL(): string {
    return 'trainingModels';
  }
  elementURL(element: TrainingModel): string {
    return `trainingModels/${encodeURIComponent(element.modelId)}`;
  }
  findIndexInList(list: TrainingModel[], element: TrainingModel): number {
    return list.findIndex(x => x.modelId === element.modelId);
  }
  sort(a: TrainingModel, b: TrainingModel): number {
    return a.name.localeCompare(b.name);
  }
}

export class ASLMedicalCentersCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.ASL_MEDICAL_CENTERS, description);
  }
  listURL(): string {
    return 'aslMedicalCenters';
  }
  elementURL(element: ASLMedicalCenter): string {
    return `aslMedicalCenters/${encodeURIComponent(element.aslId)}`;
  }
  findIndexInList(list: ASLMedicalCenter[], element: ASLMedicalCenter): number {
    return list.findIndex(x => x.aslId === element.aslId);
  }
  sort(a: ASLMedicalCenter, b: ASLMedicalCenter): number {
    return a.hospital.localeCompare(b.hospital);
  }
}

export class ASLPatientsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.ASL_PATIENTS, description);
  }
  listURL(): string {
    return 'aslPatients';
  }
  elementURL(element: ASLPatient): string {
    return `aslPatients/${encodeURIComponent(element.aslId)}`;
  }
  findIndexInList(list: ASLPatient[], element: ASLPatient): number {
    return list.findIndex(x => x.aslId === element.aslId);
  }
  sort(a: ASLPatient, b: ASLPatient): number {
    return a.aslId.localeCompare(b.aslId);
  }
}

export class ASLPMedicsCR extends CacheableResource {
  constructor(description: string) {
    super(DeltaResources.ASL_MEDICS, description);
  }
  listURL(): string {
    return 'aslMedics';
  }
  elementURL(element: ASLMedic): string {
    return `aslMedics/${encodeURIComponent(element.medicId)}`;
  }
  findIndexInList(list: ASLMedic[], element: ASLMedic): number {
    return list.findIndex(x => x.medicId === element.medicId);
  }
  sort(a: ASLMedic, b: ASLMedic): number {
    return a.medicId.localeCompare(b.medicId);
  }
}
