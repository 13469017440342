import { Resource, EmailData } from 'idea-toolbox';

import { ProductTypes } from './product.model';
import { AttachmentSlotTypes } from './attachmentSlotTypes.enum';

export class TrainingModel extends Resource {
  /**
   * The ID of the training model.
   */
  modelId: string;
  /**
   * The name of the training model.
   */
  name: string;
  /**
   * The type of training model.
   */
  type: TrainingModelTypes;
  /**
   * Primary color for the training model and the PDF's template.
   */
  color: string;
  /**
   * The options for the PDF report of the trainings of this model.
   */
  pdf: TrainingModelPDFOptions;
  /**
   * Default values for email sending.
   */
  emailDefaults: EmailData;
  /**
   * The checklist to fill-in for trainings of this model.
   */
  checklist: ChecklistElement[];
  /**
   * Product slots for the model.
   */
  productSlots: ProductSlot[];
  /**
   * Attachment slots for the model.
   */
  attachmentSlots: AttachmentSlotTypes[];
  /**
   * Number of days before trainings of this model expire.
   */
  trainingShouldEndAfterNumDays: number;
  /**
   * Whether the related training can go to the waiting list.
   */
  hasWaitingList: boolean;

  load(x: any): void {
    super.load(x);
    this.modelId = this.clean(x.modelId, String);
    this.name = this.clean(x.name, String);
    this.type = this.clean(x.type, String);
    this.color = this.clean(x.color, String);
    this.pdf = new TrainingModelPDFOptions(x.pdf);
    this.emailDefaults = new EmailData(x.emailDefaults);
    this.productSlots = this.cleanArray(x.productSlots, ps => new ProductSlot(ps));
    this.attachmentSlots = this.cleanArray(x.attachmentSlots, String);
    this.trainingShouldEndAfterNumDays = this.clean(x.trainingShouldEndAfterNumDays, Number);
    this.checklist = this.cleanArray(x.checklist, ce => new ChecklistElement(ce));
    this.hasWaitingList = this.clean(x.hasWaitingList, Boolean, false);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.modelId = safeData.modelId;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.type)) e.push('type');
    if (this.iE(this.color)) e.push('color');

    this.attachmentSlots.forEach((slot, index): void => {
      if (!Object.values(AttachmentSlotTypes).includes(slot)) e.push(`attachmentSlots[${index}]`);
    });

    return e;
  }
}

export enum TrainingModelTypes {
  DEXCOM = 'D',
  OMNIPOD = 'O'
}

export class TrainingModelPDFOptions extends Resource {
  /**
   * The content to show in the PDF report's header.
   */
  header: string;

  load(x: any): void {
    super.load(x);
    this.header = this.clean(x.header, String);
  }
}

export class ProductSlot extends Resource {
  /**
   * The type of product for this slot.
   */
  type: ProductTypes;
  /**
   * The IDs of the products allowed in this slot.
   */
  productsAllowed: string[];

  load(x: any): void {
    super.load(x);
    this.type = this.clean(x.type, String);
    this.productsAllowed = this.cleanArray(x.productsAllowed, String);
  }
}

export class ChecklistElement extends Resource {
  /**
   * The ID of the checklist element.
   */
  value: string;
  /**
   * The content of the checklist element.
   */
  name: string;
  /**
   * Optional detail (list of contents) of a checklist element.
   */
  detail?: string[];

  load(x: any): void {
    super.load(x);
    this.value = this.clean(x.value, String);
    this.name = this.clean(x.name, String);
    if (x.detail) this.detail = this.cleanArray(x.detail, String);
  }
}
