import { Injectable } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { IDEAMessageService, IDEAStorageService, IDEATranslationsService } from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { TrainingModelsService } from './trainingModels/trainingModels.service';

import { User } from '@models/user.model';
import { Training, TrainingStages, TrainingTags } from '@models/training.model';

import { environment as env } from '@env';

/**
 * The base URLs where the thumbnails are located.
 */
const THUMBNAILS_BASE_URL = env.idea.app.mediaUrl.concat('/thumbnails/images/', env.idea.api.stage, '/');
/**
 * A local fallback URL for the users avatars.
 */
const AVATAR_FALLBACK_URL = './assets/imgs/no-avatar.jpg';

@Injectable({ providedIn: 'root' })
export class AppService {
  initReady = false;
  authReady = false;

  private darkMode: boolean;

  user: User;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private message: IDEAMessageService,
    private storage: IDEAStorageService,
    private auth: IDEAAuthService,
    private t: IDEATranslationsService,
    private _models: TrainingModelsService
  ) {
    this.darkMode = this.respondToColorSchemePreferenceChanges();
  }
  private respondToColorSchemePreferenceChanges(): boolean {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => (this.darkMode = e.matches));
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Whether we should display a UX designed for smaller screens.
   */
  isMobile(): boolean {
    return this.platform.width() < 768;
  }
  /**
   * Whether we should display a UX designed for smaller screens.
   */
  isInDarkMode(): boolean {
    return this.darkMode;
  }

  /**
   * Reload the app.
   */
  reloadApp(): void {
    window.location.assign('');
  }
  /**
   * Close the current page and navigate back, optionally displaying an error message.
   */
  closePage(errorMessage?: string, pathBack?: string[]): void {
    if (errorMessage) this.message.error(errorMessage);
    try {
      this.navCtrl.back();
    } catch (_) {
      this.navCtrl.navigateBack(pathBack || []);
    }
  }

  /**
   * Get the URL to an image by its URI.
   */
  private getImageURLByURI(imageURI: string): string {
    return THUMBNAILS_BASE_URL.concat(imageURI, '.png');
  }
  /**
   * Get the URL to a user's profile image (avatar).
   */
  getUserImageURL(user: User): string {
    return user?.picture ? this.getImageURLByURI(user.picture) : AVATAR_FALLBACK_URL;
  }

  /**
   * Show some app's info.
   */
  async info(): Promise<void> {
    const openPrivacyPolicy = () => Browser.open({ url: this.t._('IDEA_VARIABLES.PRIVACY_POLICY_URL') });

    const header = this.t._('COMMON.APP_NAME');
    const message = this.t._('COMMON.VERSION', { v: env.idea.app.version });
    const buttons = [
      { text: this.t._('IDEA_AUTH.PRIVACY_POLICY'), handler: openPrivacyPolicy },
      { text: this.t._('COMMON.CLOSE') }
    ];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }

  /**
   * Sign-out from the current user.
   */
  async logout(): Promise<void> {
    const doLogout = () => this.auth.logout().finally(() => this.storage.clear().then(() => this.reloadApp()));

    const header = this.t._('COMMON.LOGOUT');
    const message = this.t._('COMMON.ARE_YOU_SURE');
    const buttons = [{ text: this.t._('COMMON.CANCEL') }, { text: this.t._('COMMON.LOGOUT'), handler: doLogout }];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }

  /**
   * Utility to generate a numeric array.
   * Useful for skeleton interfaces.
   */
  generateNumericArray(length: number): number[] {
    return [...Array(length).keys()];
  }

  /**
   * Get meta info about the possible trainings stages.
   */
  getTrainingStagesMeta(): { stage: TrainingStages; color: string; icon: string }[] {
    return [
      { stage: TrainingStages.DRAFT, color: 'medium', icon: 'egg' },
      { stage: TrainingStages.CONFIRMED, color: 'primary', icon: 'checkmark-done' },
      { stage: TrainingStages.CONFIRMED_ENDED, color: 'warning', icon: 'alert-circle' },
      { stage: TrainingStages.DOCUMENT_LINKED, color: 'success', icon: 'play-forward' },
      { stage: TrainingStages.HAS_OUTCOME, color: 'secondary', icon: 'send' }
    ];
  }
  /**
   * Get meta info about the possible trainings tags.
   */
  getTrainingTagsMeta(): { tag: TrainingTags; color: string; icon: string }[] {
    return [{ tag: TrainingTags.SHOULD_RECOVER_TRIAL_EQUIPMENT, color: 'secondary', icon: 'magnet' }];
  }

  async chooseModelAndStartNewTraining(copyDataFromTraining?: Training): Promise<void> {
    const models = await this._models.getList();
    const openTrainingPage = (modelId: string) => {
      if (!modelId) return;
      this.navCtrl.navigateForward(['trainings', 'new'], {
        queryParams: { modelId },
        state: { training: copyDataFromTraining }
      });
    };

    const header = this.t._('TRAINING.CHOOSE_A_MODEL');
    const inputs: any[] = models.map(m => ({ type: 'radio', label: m.name, value: m.modelId }));
    const buttons = [this.t._('COMMON.CANCEL'), { text: this.t._('COMMON.CONFIRM'), handler: openTrainingPage }];

    const alert = await this.alertCtrl.create({ header, inputs, buttons });
    alert.present();
  }
}
