import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { initGuard } from './init.guard';
import { authGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'trainings',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('@idea-ionic/auth').then(m => m.IDEAAuthModule),
    canActivate: [initGuard]
  },
  {
    path: 'trainings',
    loadChildren: () => import('./trainings/trainings.module').then(m => m.TrainingsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'deliveries',
    loadChildren: () => import('./deliveries/deliveries.module').then(m => m.DeliveriesModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'regions',
    loadChildren: () => import('./regions/regions.module').then(m => m.RegionsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'asls',
    loadChildren: () => import('./medicalCenters/medicalCenters.module').then(m => m.MedicalCentersModule),
    canActivate: [initGuard, authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
