import { epochISODateString, Resource, toISODate } from 'idea-toolbox';

import { Data } from './data.model';

export class Patient extends Resource {
  surname: string;
  name: string;
  address: string;
  city: string;
  district: string;
  postalCode: string;
  aslId: string;
  birthdate: epochISODateString;
  cellphone: string;
  email: string;
  phone: string;
  legalGuardian: string;
  taxCode: string;

  load(x: any): void {
    super.load(x);
    this.surname = this.clean(x.surname, String);
    this.name = this.clean(x.name, String);
    this.address = this.clean(x.address, String);
    this.city = this.clean(x.city, String);
    this.district = this.clean(x.district, String);
    this.postalCode = this.clean(x.postalCode, String);
    this.aslId = this.clean(x.aslId ?? x.asl, String);
    this.birthdate = this.clean(x.birthdate, d => toISODate(d));
    this.cellphone = this.clean(x.cellphone, String);
    this.email = this.clean(x.email, String);
    this.phone = this.clean(x.phone, String);
    this.legalGuardian = this.clean(x.legalGuardian, String);
    this.taxCode = this.clean(x.taxCode, t => String(t).toUpperCase());
  }

  validate(options: { data: Data }): string[] {
    const e = super.validate();
    if (this.iE(this.surname)) e.push('surname');
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.address)) e.push('address');
    if (this.iE(this.city)) e.push('city');
    if (this.iE(this.district) || !options.data.districts.includes(this.district)) e.push('district');
    if (this.iE(this.postalCode)) e.push('postalCode');
    if (this.iE(this.aslId)) e.push('aslId');
    if (this.iE(this.birthdate, 'date')) e.push('birthdate');
    if (this.iE(this.taxCode)) e.push('taxCode');
    return e;
  }
}
