import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

import { ServiceWorkerModule } from '@angular/service-worker';

import {
  IDEATranslationsModule,
  IDEAActionSheetModule,
  IDEAOfflineDataService,
  IDEAOfflineService,
  IDEAAWSAPIService,
  IDEAErrorReportingService
} from '@idea-ionic/common';

import { IDEAEnvironment } from '@idea-ionic/common';
import { environment } from '@env';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    IonicStorageModule.forRoot({ name: 'tfm' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.debug,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IDEATranslationsModule,
    IDEAActionSheetModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    IDEAAWSAPIService,
    IDEAErrorReportingService,
    IDEAOfflineService,
    IDEAOfflineDataService,
    { provide: IDEAEnvironment, useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
