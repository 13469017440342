import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, MenuController, ModalController } from '@ionic/angular';
import { IDEAOfflineDataService, IDEAOfflineManagerComponent, IDEATranslationsService } from '@idea-ionic/common';

import { environment as env } from '@env';

import { AppService } from './app.service';
import { TrainingModelsService } from './trainingModels/trainingModels.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private _models: TrainingModelsService,
    public menuCtrl: MenuController,
    public offline: IDEAOfflineDataService,
    public app: AppService,
    public t: IDEATranslationsService
  ) {}

  shouldHideSplitPane(): boolean {
    return !this.app.user;
  }
  isPageWithoutMenu(): boolean {
    return !this.router.url || ['echo', 'auth'].some(x => this.router.url.startsWith('/' + x));
  }

  goToPage(path: string | string[], queryParams?: any): void {
    if (!Array.isArray(path)) path = [path];
    this.router.navigate(path, { queryParams });
    this.menuCtrl.close();
  }

  async openOfflineManager(): Promise<void> {
    const modal = await this.modalCtrl.create({ component: IDEAOfflineManagerComponent });
    modal.present();
  }

  /**
   * Show some app's info.
   */
  async showAppInfo(): Promise<void> {
    const header = this.t._('COMMON.APP_NAME');
    const message = this.t._('COMMON.VERSION', { v: env.idea.app.version });
    const buttons = [{ text: this.t._('COMMON.CLOSE') }];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }
}
