import { Injectable } from '@angular/core';
import { CacheModes, IDEAAWSAPIService } from '@idea-ionic/common';

import { TrainingModel } from '@models/trainingModel.model';

@Injectable({ providedIn: 'root' })
export class TrainingModelsService {
  private models: TrainingModel[] = null;

  constructor(private api: IDEAAWSAPIService) {}

  /**
   * Load the list of training models from the back-end.
   */
  private async loadList(): Promise<void> {
    this.models = (await this.api.getResource('trainingModels', { useCache: CacheModes.NETWORK_FIRST })).map(
      (c: TrainingModel): TrainingModel => new TrainingModel(c)
    );
  }

  /**
   * Get the list of training models.
   */
  async getList(options: { force?: boolean } = {}): Promise<TrainingModel[]> {
    if (!this.models || options.force) await this.loadList();
    if (!this.models) return null;
    return this.models;
  }

  /**
   * Get the full details of a training model by its id.
   */
  async getById(id: string): Promise<TrainingModel> {
    return new TrainingModel(
      await this.api.getResource('trainingModels', { resourceId: id, useCache: CacheModes.NETWORK_FIRST })
    );
  }
}
