import { Resource } from 'idea-toolbox';

import { Data } from './data.model';
import { ASLMedic } from './aslMedic.model';

export class MedicalCenter extends Resource {
  aslId: string;
  hospital: string;
  address: string;
  city: string;
  area: string;
  medic: ASLMedic;

  load(x: any): void {
    super.load(x);
    this.aslId = this.clean(x.aslId ?? x.asl, String);
    this.hospital = this.clean(x.hospital, String);
    this.address = this.clean(x.address, String);
    this.city = this.clean(x.city, String);
    this.area = this.clean(x.area, String);
    this.medic = new ASLMedic(x.medic);
  }

  validate(options: { data: Data }): string[] {
    const e = super.validate();
    if (this.iE(this.aslId)) e.push('aslId');
    if (this.iE(this.hospital)) e.push('hospital');
    if (this.iE(this.city)) e.push('city');
    if (this.iE(this.area) || !options.data.medicalCenterAreas.includes(this.area)) e.push('area');
    const medicErrors = this.medic.validate();
    if (medicErrors.length) e.push('medic');
    return e;
  }
}
