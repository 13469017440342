import { CognitoUser, Resource } from 'idea-toolbox';

import { ASLMedicalCenter } from './aslMedicalCenter.model';

export class User extends CognitoUser {
  /**
   * The IDs of the companies linked to the user.
   */
  getCompaniesIds(): string[] {
    return this.groups.filter(g => !['admins', 'robots', 'externals', 'sensitive'].includes(g));
  }
  /**
   * Return the first company's id.
   */
  getMainCompanyId(): string {
    return this.getCompaniesIds()[0];
  }
  /**
   * Whether the user is external.
   */
  isExternal(): boolean {
    return this.groups.includes('externals');
  }
  /**
   * Whether the user belongs to the sensitive group.
   */
  canExportSensitiveData(): boolean {
    return this.groups.includes('sensitive');
  }

  /**
   * Get the areas that the user can manage.
   */
  getManagedAreas(): string[] {
    return String(this.attributes.managedAreas).split(',');
  }
  /**
   * Get the regions that the user can manage.
   */
  getManagedRegions(): string[] {
    return String(this.attributes.managedRegions).split(',');
  }
  /**
   * Get the companies where the user has administrator permissions.
   */
  getAdministratedCompanies(): string[] {
    if (!this.attributes.adminCompanies) return [];
    return String(this.attributes.adminCompanies).split(',');
  }
  /**
   * Whether the user can manage over a given area/region.
   * If user can manage the region's area, he can also manage its regions.
   */
  canManageBasedOnAslRegion(aslId: string, aslMedicalCenters: ASLMedicalCenter[]): boolean {
    if (!aslId) return false;

    const medicalCenter = aslMedicalCenters.find(m => m.aslId === aslId);
    if (!medicalCenter) return false;

    const { region } = medicalCenter;
    if (!region) return false;

    const canManageArea = region.area ? this.getManagedAreas().some(x => x === region.area) : false;
    const canManageRegion = region.regionId ? this.getManagedRegions().some(x => x === region.regionId) : false;

    return canManageArea || canManageRegion;
  }

  isAdmin(companyId?: string): boolean {
    if (super.isAdmin()) return true;
    else if (companyId) return this.getAdministratedCompanies().some(x => x === companyId);
    else return false;
  }
}

export class UserAttached extends Resource {
  userId: string;
  name: string;

  load(x: any): void {
    super.load(x);
    this.userId = this.clean(x.userId, String);
    this.name = this.clean(x.name, String);
  }
}
