<ion-app>
  <ion-split-pane contentId="main" when="md" [disabled]="shouldHideSplitPane() || isPageWithoutMenu()">
    <ion-menu contentId="main" type="overlay">
      <ion-header class="ion-no-border">
        <ion-toolbar color="ideaToolbar">
          <ion-item lines="none" class="menuHeaderItem">
            <ion-label>
              {{ app.user?.name }}
              <p>{{ app.user?.getMainCompanyId() }}</p>
            </ion-label>
            <ion-thumbnail slot="end" class="tappable" (click)="menuCtrl.close()">
              <ion-img src="/assets/icons/icon-store.jpg"></ion-img>
            </ion-thumbnail>
          </ion-item>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list lines="none">
          <ion-item-divider>
            <ion-label>{{ 'MENU.TRAININGS' | translate }}</ion-label>
          </ion-item-divider>
          <ion-item (click)="app.chooseModelAndStartNewTraining()" button>
            <ion-icon icon="create" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.NEW_TRAINING' | translate }}</ion-label>
          </ion-item>
          <ion-item (click)="goToPage('trainings')" button>
            <ion-icon icon="body" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.TRAININGS_LIST' | translate }}</ion-label>
          </ion-item>
          <ion-item *ngIf="offline.isOnline()" (click)="goToPage(['trainings', 'archive'])" button>
            <ion-icon icon="stats-chart" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.TRAININGS_STATS' | translate }}</ion-label>
          </ion-item>
          <ion-item-divider>
            <ion-label>{{ 'MENU.DELIVERIES' | translate }}</ion-label>
          </ion-item-divider>
          <ion-item (click)="goToPage(['deliveries', 'new'])" button>
            <ion-icon icon="cube" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.NEW_DELIVERY' | translate }}</ion-label>
          </ion-item>
          <ion-item (click)="goToPage('deliveries')" button>
            <ion-icon icon="albums" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.DELIVERIES_LIST' | translate }}</ion-label>
          </ion-item>
          <ion-item *ngIf="offline.isOnline()" (click)="goToPage(['deliveries', 'archive'])" button>
            <ion-icon icon="compass" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.DELIVERIES_STATS' | translate }}</ion-label>
          </ion-item>
          <ng-container *ngIf="app.user?.isAdmin()">
            <ion-item-divider>
              <ion-label>{{ 'MENU.REGIONS_ASL' | translate }}</ion-label>
            </ion-item-divider>
            <ion-item (click)="goToPage('regions')" button>
              <ion-icon icon="map" slot="start"></ion-icon>
              <ion-label>{{ 'MENU.REGIONS' | translate }}</ion-label>
            </ion-item>
            <ion-item (click)="goToPage('asls')" button>
              <ion-icon icon="medkit" slot="start"></ion-icon>
              <ion-label>{{ 'MENU.ASL' | translate }}</ion-label>
            </ion-item>
          </ng-container>
          <ion-item-divider>
            <ion-label>{{ 'MENU.OTHER' | translate }}</ion-label>
          </ion-item-divider>
          <ion-item *ngIf="offline.isOnline()" (click)="openOfflineManager()" button>
            <ion-icon icon="download" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.OFFLINE_DATA' | translate }}</ion-label>
          </ion-item>
          <ion-item (click)="showAppInfo()" button>
            <ion-icon icon="information-circle" slot="start"></ion-icon>
            <ion-label>{{ 'MENU.INFO' | translate }}</ion-label>
          </ion-item>
          <ion-item (click)="app.logout()" button>
            <ion-icon icon="exit" slot="start"></ion-icon>
            <ion-label>{{ 'COMMON.LOGOUT' | translate }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
