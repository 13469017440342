import { Resource } from 'idea-toolbox';

import { Data } from './data.model';

export class PreviousDevices extends Resource {
  monitoring: string;
  infusion: string;

  load(x: any) {
    super.load(x);
    this.monitoring = this.clean(x.monitoring, String);
    this.infusion = this.clean(x.infusion, String);
  }

  validate(options: { data: Data }): string[] {
    const e = super.validate();
    if (this.iE(this.monitoring) || !options.data.previousDevicesMonitoring.includes(this.monitoring))
      e.push('monitoring');
    if (this.iE(this.infusion) || !options.data.previousDevicesInfusion.includes(this.infusion)) e.push('infusion');
    return e;
  }
}
