import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import {
  IDEAOfflineDataService,
  IDEAStorageService,
  IDEATinCanService,
  IDEATranslationsService
} from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { AppService } from './app.service';

import { User } from '@models/user.model';
import {
  ASLPatientsCR,
  ASLMedicalCentersCR,
  CompanyCR,
  DataCR,
  ProductsCR,
  TrainingModelsCR
} from './cacheableResources.model';

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const navCtrl = inject(NavController);
  const storage = inject(IDEAStorageService);
  const t = inject(IDEATranslationsService);
  const tc = inject(IDEATinCanService);
  const auth = inject(IDEAAuthService);
  const offline = inject(IDEAOfflineDataService);
  const app = inject(AppService);

  if (app.authReady) return true;

  //
  // HELPERS
  //

  const doAuth = async (): Promise<void> => {
    const authRes = await auth.isAuthenticated(true, freshIdToken => tc.set('AWSAPIAuthToken', freshIdToken));
    tc.set('AWSAPIAuthToken', authRes.idToken);
    app.user = new User(authRes.userDetails);
  };

  const configureOfflineManagement = async (): Promise<void> => {
    offline.setUpOfflineData(null, false, [
      new DataCR(t._('ENTITIES.SUPPORT_DATA')),
      new CompanyCR(t._('ENTITIES.COMPANIES')),
      new ProductsCR(t._('ENTITIES.PRODUCTS')),
      new TrainingModelsCR(t._('ENTITIES.TRAINING_MODELS')),
      new ASLMedicalCentersCR(t._('ENTITIES.ASL_MEDICAL_CENTERS')),
      new ASLPatientsCR(t._('ENTITIES.ASL_PATIENTS')),
      new ASLMedicalCentersCR(t._('ENTITIES.ASL_MEDICS'))
    ]);

    await offline.synchronize();
  };

  const navigateAndResolve = (navigationPath?: string[]): boolean => {
    if (navigationPath) navCtrl.navigateRoot(navigationPath);
    app.authReady = true;
    return true;
  };

  //
  // MAIN
  //

  if (app.authReady) return true;

  await platform.ready();
  await storage.ready();

  try {
    await doAuth();
    platform.resume.subscribe((): Promise<void> => doAuth());

    configureOfflineManagement();

    if (window.location.pathname === '/') return navigateAndResolve([]);
    return navigateAndResolve();
  } catch (err) {
    return navigateAndResolve(['auth']);
  }
};
