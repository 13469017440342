import { Resource } from 'idea-toolbox';

export class ASLMedic extends Resource {
  medicId: string;
  fullName: string;
  email: string;
  aslIds: string[];

  load(x: any): void {
    super.load(x);
    this.medicId = this.clean(x.medicId, String);
    this.fullName = this.clean(x.fullName, String);
    this.email = this.clean(x.email, String);
    this.aslIds = this.cleanArray(x.aslIds, String);
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.medicId = safeData.medicId;
  }

  safeLoadFromOTM(newData: any, safeData: any): void {
    this.safeLoad(newData, safeData);
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.medicId)) e.push('medicId');
    if (this.iE(this.fullName)) e.push('fullName');
    return e;
  }
}
